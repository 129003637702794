import React from "react"

import SliderDestinations from "../components/slider-destinations"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PageIntro from "../components/pageintro"
import SliderThemes from"../components/slider-themes"

const IndexPage = () => (
  <Layout>
    <SEO title="A guide to UK Staycations" description="Need ideas for a UK staycation? With activities, destinations and the best things to do, we've got your covered." />
    <h1>A guide to holidaying in the UK</h1>
    <div style={{ marginBottom: `1.45rem` }}>
    <PageIntro>
    <h3>The UK is an incredible holiday destination.</h3>
    <p>Millions of tourists from around the world flock to the UK each year to explore the history, culture, landscapes and shopping. So why do so many brits ignore this incredible destinatin and fly abroad rather than exploring what's right on their own doorsteps? </p>
    <p>We want to highlight what the UK has that's great - so for first time staycationers, and seasoned travellers alike, explore below to find great ideas for your next staycation.</p>
    </PageIntro>
  <SliderDestinations/>
  <SliderThemes/>
    </div>
  </Layout>
)

export default IndexPage
